<template>
  <vue-modal
    :modal-title="$t('pages.planing-team-validate.hours_copy')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :loading-primary-footer-button="isLoading"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[secondaryEvent]="onCloseButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <info-component :message="title"></info-component>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";
import InfoComponent, { INFO_TYPE_INFO_COMPONENT } from "@/pages/components/info";
import { arrayWrap, copyMinutesToHoursField } from "@/utils/functions";

import { SHOW_CONFIRM_HOUR_MODAL } from "@/pages/responsible/planing/data/events";

import api from "@/api/plan";

export default {
  components: {
    VueModal,
    InfoComponent,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      validateDayItems: [],
      title: null,
      type: null,
    };
  },
  created() {
    EventBus.listen(SHOW_CONFIRM_HOUR_MODAL, ({ title, type, validateDayItems }) => {
      this.validateDayItems = arrayWrap(validateDayItems);
      this.title = title;
      this.type = type;
      this.showModal = true;
    });
  },
  beforeDestroy() {
    EventBus.off(SHOW_CONFIRM_HOUR_MODAL);
  },
  computed: {
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
    infoType() {
      return INFO_TYPE_INFO_COMPONENT;
    },
  },
  methods: {
    async onPrimaryButtonClicked() {
      this.isLoading = true;

      if (this.type === "planned") {
        await api.setDayApprovedHours(
          this.validateDayItems
            .filter((x) => !x.employeePlan.fired && this.$isset(x.employeePlan.key))
            .filter((x) => this.$isset(x.employeePlan.key))
            .map((x) => {
              return {
                key: x.employeePlan.key,
                hour: `${x.employeePlan.planningHours}`,
                nightHour: `${x.employeePlan.nightHours}`,
              };
            })
        );
      } else if (this.type === "biometric") {
        var hours = this.validateDayItems
          .filter((x) => !x.employeePlan.fired)
          .filter((x) => this.$isset(x.employeePlan.key) && x.employeePlan.key != 0)
          .map((x) => {
            var biometricHours = x.employeePlan.dayBiometricHours;
            if (
              !this.$isset(biometricHours) ||
              biometricHours == "" ||
              biometricHours == 0 ||
              biometricHours == "0"
            ) {
              if (
                this.$isset(x.biometric) &&
                x.biometric.isValid &&
                x.biometric.totalMinutes <= 1440
              ) {
                biometricHours = copyMinutesToHoursField(x.biometric.totalMinutes);
              }
            }
            return {
              key: x.employeePlan.key,
              hour: `${biometricHours}`,
              nightHour: `${x.employeePlan.nightHours}`,
            };
          });
        await api.setDayApprovedHours(hours);
      }

      this.$emit("changed");
      this.clear();
      this.isLoading = false;
      this.showModal = false;
    },
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.clear();
      this.showModal = false;
    },
    clear() {
      this.hours = [];
      this.title = "";
      this.type = "";
    },
  },
};
</script>
